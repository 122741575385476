import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
export default class LoadingDialog extends React.Component {

    render() {
        const { message } = this.props;

        return (
            <div>
              <CircularProgress color="secondary" size={20} />
              {message && <span style={{marginLeft: "10px"}} />}{message}
            </div>
          )
    }
}
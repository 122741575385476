import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import configureReactStore from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID

root.render(
  //<React.StrictMode>
  <Provider store={configureReactStore()}>    
    <GoogleOAuthProvider clientId={googleAuthClientId}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
  //</React.StrictMode>
);

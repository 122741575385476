export default class ListsHelper {

  static samplesList = [
    {
      value: '0',
      label: '',
      prefix: '',
      suffix: '',
    },  
    {
      value: '1',
      label: 'SOC Code',
      prefix: 'Identity the SOC code for this job tile:',
      jobTitle: 'Senior Software Engineer',
      suffix: '',
    },
    {
      value: '2',
      label: 'SOC Code Detailed',
      prefix: 'Given this Job Title:',
      jobTitle: 'Senior Software Engineer',
      suffix: 'Provide SOC code insights in JSON format with the following keys: major_group, minor_group, series, soc_code, soc_title, soc_description, soc_education, soc_experience, soc_salary, soc_job_outlook, soc_job_outlook_details, soc_job_related_occupations list with soc_code, soc_title, source',
    },
    {
      value: '3',
      label: 'Salary Insights',
      prefix: 'Given this Job Title:',
      jobTitle: 'Senior Software Engineer',
      suffix: 'Return the salary insights in a JSON format containing: salary_range_min, salary_range_med, salary_range_max, top_10_percent_annual_salary, bottom_10_percent_annual_salaryProvide SOC code insights in JSON format with the following keys: major_group, minor_group, series, soc_code, soc_title, soc_description, soc_education, soc_experience, soc_salary, soc_job_outlook, soc_job_outlook_details, soc_job_related_occupations list with soc_code, soc_title, source',
    },
    {
      value: '4',
      label: 'Salary Comparison',
      prefix: 'Given this Job Title',
      jobTitle: 'Senior Software Engineer',
      suffix: 'and the salary {Salary} in {Location} provide the salary deviation comparing with similar jobs',
    },
    {
      value: '5',
      label: 'List of Skills',
      prefix: 'Given this Job Title',
      jobTitle: 'Senior Software Engineer',
      suffix: 'Provide the list of skills for this job position based in skills based hiring instead of bachelor degree requirement in a json format with the following keys: name, description, level, category, quality',
    },
    {
      value: '6',
      label: 'Critical Day 1 skills',
      prefix: 'Given this Job Title',
      jobTitle: 'Senior Software Engineer',
      suffix: 'Return a list of the most critical 1 day skills for this job in a JSON format containing skill_name, importance, skill_description, skill_type, skill_category',
    },
    {
      value: '7',
      label: 'Skills Comparison',
      prefix: 'Compare the skills of this job with the skills related to the SOC code:',
      jobTitle: '[Required for this Sample]',
      jobDescription: '[Required for this Sample]',
      suffix: '',
    },  
    {
      value: '8',
      label: 'STAR-friendly Job Description',
      prefix: 'Given this job description:',
      jobDescription: '[Required for this Sample]',
      suffix: 'Re-format the job description replacing the bachelor`s degree requirement with equivalent skills and experiences required for the job',
    },
    {
      value: '9',
      label: 'Source Occupations',
      prefix: 'Given this Job Title:',
      jobTitle: '[Required for this Sample]',
      jobDescription: '',
      suffix: 'Obtain a list of source jobs with established pathways to this job including the skills gap for each one. Return a JSON list containing these attributes: source_job_title, source_job_description, source_job_soc_code, pathway_analysis, skills_gap list with skill_name, skill_type, skill_level, skill_category',
    },
    {
      value: '10',
      label: 'Similar Jobs',
      prefix: 'Given this Job Title:',
      jobTitle: 'Senior Software Engineer',
      jobDescription: '',
      suffix: 'Return a JSON list of jobs with similar requirements containing title, list of requirements and proximity',
    },
    {
      value: '11',
      label: 'Growth Projections',
      prefix: 'Given this Job Title:',
      jobTitle: 'Senior Software Engineer',
      suffix: 'Provide the outlook for future growth from this job in a json format including a list of promotions and lateral_moves containing title, description and requirements',
    },
    {
      value: '12',
      label: 'Essential Keywords',
      prefix: 'Identify the essential keywords in this job description :',
      jobDescription: '[Required for this Sample]',
      suffix: '',
    },
    {
      value: '13',
      label: 'Information Extraction',
      prefix: 'Given this job description:',
      jobDescription: '[Required for this Sample]',
      suffix: 'Return JSON format the job title, list of skills, qualifications, responsibilities and a boolean key requires_bachelor_degree with True if the job requires a bachelor degree and False otherwise',
    }
  ];

  static marksTemperature = [
    {
      value: 0,
      label: '0',
    },  
    {
      value: 1,
      label: '1',
    },
  ];

  static marksTokens = [
    {
      value: 1,
      label: '1',
    },  
    {
      value: 8192,
      label: '8192',
    },
  ];
}
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { connect } from "react-redux";
import usersAction from '../actions/usersAction';
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';

const allowedDomains = (process.env.REACT_APP_ALLOWED_DOMAINS || '').split(',');

class Login extends React.Component {

    validateEmailDomain(email) {
      let isDomainAllowed = false;
      allowedDomains.forEach((domain) => {
        if (email.endsWith(domain.toLowerCase())) {
          isDomainAllowed = true;
          return;
        }
      })
      return isDomainAllowed;
    }

    validateUserToken(userToken) {
        const decodedToken = jwtDecode(userToken);
        const email = decodedToken.email.toLowerCase();
        const isDomainAllowed = this.validateEmailDomain(email);
        const isAuthenticated = true;

        this.props.setUserNameAction(decodedToken.name);
        this.props.setUserEmailAction(decodedToken.email);
        this.props.setUserPictureAction(decodedToken.picture);
        this.props.setUserIsAuthenticatedAction(isAuthenticated);
        this.props.setUserIsDomainAllowedAction(isDomainAllowed);
        
        if (isAuthenticated && isDomainAllowed) {
          localStorage.setItem('userToken', userToken);
          return true;          
        }

        return false;
    }

    componentDidMount() {
        if (window.location.hostname === "oaw-genai-ui.s3-website-us-east-1.amazonaws.com") {
          window.open("https://genai-sandbox.stellarworx.org", "_self");
        }
      }

    getUserToken() {
      const loggedInToken = localStorage.getItem("userToken");

      let userValid = false;
      if (loggedInToken) {
        userValid = this.validateUserToken(loggedInToken);
      }
      return userValid;
    }

    render() {

        const userValid = this.getUserToken();
        if (userValid) {
          return <Navigate to="/" />
        }

        return (
            <div align='center'>
              <br /><br />
              <GoogleLogin
                auto_select={true}
                useOneTap={true}
                isSignedIn={true}
                cookiePolicy={'single_host_origin'}
                onSuccess={credentialResponse => {                  
                  this.validateUserToken(credentialResponse.credential);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>    
        );
    }
    
}

const mapStateToProps = state => ({
    ...state
  });
  
  const mapDispatchToProps = dispatch => ({
    setUserNameAction: (userName) => dispatch(usersAction.setUserNameAction(userName)),
    setUserEmailAction : (userEmail) => dispatch(usersAction.setUserEmailAction(userEmail)),
    setUserPictureAction: (userPicture) => dispatch(usersAction.setUserPictureAction(userPicture)),
    setUserIsAuthenticatedAction: (isAuthenticated) => dispatch(usersAction.setUserIsAuthenticatedAction(isAuthenticated)),
    setUserIsDomainAllowedAction: (isDomainAllowed) => dispatch(usersAction.setUserIsDomainAllowed(isDomainAllowed)),
    //setColorAction: (payload) => dispatch(setColorAction(payload))
  });

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React from 'react';
import './App.css';
import PromptForm from './components/PromptForm';
import { connect } from "react-redux";
import Header from './components/Header';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import AccessRestricted from './components/AccessRestricted';
import Login from './components/Login';
import PrivacyPolicy from './components/PrivacyPolicy';
import JobIndustry from './components/widgets/JobIndustry';

const useGoogleAuth = process.env.REACT_APP_USE_GOOGLE_AUTH

class App extends React.Component {

  render() {
    const { isAuthenticated, isDomainAllowed } = this.props;
    const location = window.location.pathname.toString();

    if (location.indexOf('/widgets') > -1) {
      return (
        <Routes>
          <Route path='/widgets/job-industry' element={<JobIndustry/>} />
        </Routes>
      )
    }


    if (useGoogleAuth && isAuthenticated && !isDomainAllowed) {
      return (<AccessRestricted />);
    }

    return (
      <div className="App">    
        <Header />
        <main>
          <Routes>
              <Route path="/" element={<PromptForm />}/>
              <Route path="/login" element={<Login/>} />
              <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          </Routes>
        </main>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state
});
export default connect(mapStateToProps)(App);
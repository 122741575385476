import React from 'react';
import ModelsDropDown from './ModelsDropDown';
import TemperatureSlider from './TemperatureSlider';
import TokenSlider from './TokenSlider';
import OAWSourceDropDown from './OAWSourceDropDown';

export default class ParametersForm extends React.Component {

    render() {
        
        const { temperature, tokens, selectedModel, modelChangeFunc, temperatureChangeFunc, tokenChangeFunc, changeApiFunc, selectedApi } = this.props;

        return (
            <table border="0" width={'80%'} cellPadding={1} cellSpacing={0}>
                <tbody>
                    <tr>
                    <td valign='top'>
                        <OAWSourceDropDown onChangeFunc={changeApiFunc} selectedApi={selectedApi} />
                        <br /><br />
                        <ModelsDropDown onChangeFunc={modelChangeFunc} selectedModel={selectedModel} />
                    </td>
                    <td valign='top'>
                        <TemperatureSlider onChangeFunc={temperatureChangeFunc} temperature={temperature} />
                    </td>
                    <td width={100}></td>
                    <td valign='top'>
                        <TokenSlider onChangeFunc={tokenChangeFunc} tokens={tokens} />
                    </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
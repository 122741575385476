import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileService from '../services/fileService';
import GenAIService from '../services/genAIService';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";

class PromptInsightsButton extends React.Component {
  state = {
    isLoading: false
  }

  getPromptInsights(e) {
    this.setState({ isLoading: true });
    const { userEmail } = this.props;

    const domain = (userEmail.split('@')[1] || '').toLowerCase();

    GenAIService.listInsights(domain).then(insightsList => {
      const fileHeaders = ['id', 'name', 'email', 'prompt', 'response', 'response_status', 'response_time', 'helpful_answer', 'user_feedback', 'query_datetime' ]
      
      FileService.downloadCSV(insightsList, fileHeaders);
    })
    .catch(error => {
      console.log('Error getting prompt insights', error);
      alert('Error getting prompt insights');
    })
    .finally(() => {
      this.setState({ isLoading: false });
    });
  }

   render() {
      const { isLoading } = this.state;

      return (
        <>
        {!isLoading && <Tooltip size="12" title="Download Prompt Insights" onClick={(e) => this.getPromptInsights(e)}>
            <Button
              startIcon={<FileDownloadOutlinedIcon color="primary" />}
              style={{textTransform: "none"}}
            >
              Prompt Insights
            </Button>
          </Tooltip>
        }
        {isLoading && <CircularProgress />}
        </>
      )
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(PromptInsightsButton)
import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
export default class FreeForm extends React.Component {

    keyUp(e, submitFormFunc) {

        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault();
            submitFormFunc(e);
        }
    }

    render() {
        const { changePromptFunc, submitFormFunc, prompt, clearFunc, isLoading } = this.props;

        return (
            <>
            <TextField                
                id="txtPrompt"
                label="Prompt"                      
                style={{ width: 600 }}
                multiline={true}
                rows={15}
                size='small'
                value={prompt}
                onChange={changePromptFunc}
                autoFocus={true}
                onKeyUp={((e) => this.keyUp(e, submitFormFunc))}
                />
                <br /><br />
                <Button variant="outlined" size='small' onClick={clearFunc} style={{textTransform: "none"}} >Clear</Button>
                <span style={{padding: "20px"}}></span>
                <Button variant="outlined" size='small' type='submit' disabled={isLoading} style={{textTransform: "none"}}>Submit</Button>
            </>
        )
    }
}
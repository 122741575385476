import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import GenAIService from '../../services/genAIService';
export default class JobIndustry extends React.Component {

    state = {
        job_title: this.props.job_title || '',
        is_searching: false,
        industry: ''
    };

    getIndustry(e) {
        const { job_title } = this.state;

        e.preventDefault();

        if (!job_title) {
            alert('Please enter a job title');
            return;
        }

        this.setState({ is_searching: true });
        GenAIService.getIndustry(job_title).then(data => {
            this.setState({ industry: data.industry });
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.setState({ is_searching: false });
        });
    }

    changeIndustry(e) {
        e.preventDefault();
        this.setState({ job_title: e.target.value });
    }

    render() {
        const { job_title, is_searching, industry } = this.state;

        return (
            <div id="oaw-job-industry" style={{padding: "20px"}}>
                <TextField                
                id="txtJobTitle"
                label="Job Title"                      
                style={{ width: 400, marginRight: "10px" }}
                size='small'                
                autoFocus={true}                
                value={job_title}
                onChange={(e) => this.changeIndustry(e)}
                />
                { is_searching && <CircularProgress color="secondary" size={20} style={{ marginTop: "10px"}} /> }
                { !is_searching && 
                    <Button variant="outlined" size='small' type='submit' style={{ height: "40px", textTransform: "none"}} onClick={(e) => this.getIndustry(e)} title='This widget is powered by Opportunnity At Work using Google Generative AI to identify the industry related to a job title based on O*Net data.'>Get Industry</Button>
                }
                <br /><br />
                { industry && <span><b>Industry:</b> {industry}</span> }
            </div>
          )
    }
}
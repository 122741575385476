import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
export default class OAWSourceDropDown extends React.Component {

    render() {
        const { onChangeFunc, selectedApi} = this.props;

        return (
          <TextField                
            id="selOAWApi"
            select
            label="Source" 
            style={{ width: 200 }}
            size='small'            
            title='Source of the information to be used for the prompt'
            align="left"            
            onChange={onChangeFunc}
            value={selectedApi}
          >
              <MenuItem value='combined' title='RAG Pipeline is que combination of the OAW Assets, SMDM and Vertex AI' >RAG Pipeline</MenuItem>
              <MenuItem value='oaw_assets' title='OAW Assets is the list of unstructured OAW documents used by the LLM to retrieve information'>OAW Assets</MenuItem>
              <MenuItem value='smdm' title='SMDM is the STARs Mobility Data Model used by the LLM to retrieve information'>SMDM</MenuItem>
              <MenuItem value='vertexai' title='This option will use VertexAI only without combining the response with OAW Assets or SMDM'>Vertex AI</MenuItem>
          </TextField>
          )
    }
}
import { createStore } from "redux";
import rootReducer from "./reducers/rootReducer";

function configureStore(state = { 
    userName: "userTest", 
    userEmail: "emailTest",
  }
  ) {
  return createStore(rootReducer,state);
}
export default configureStore;
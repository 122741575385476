import logo from '../img/oaw-logo.svg';
import React from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { googleLogout } from '@react-oauth/google';
import { connect } from "react-redux";
import usersAction from '../actions/usersAction';

const useGoogleAuth = process.env.REACT_APP_USE_GOOGLE_AUTH

class Header extends React.Component {

    userProfileWidget() {
        const { userName, userEmail, userPicture } = this.props;
        return (
          <table border="0">
            <tbody>
              <tr>
                <td rowSpan={2} align='right' width={40}>                  
                  <img src={userPicture} alt="Profile" style={{ width: 40, height: 40, borderRadius: '50%' }} />
                </td>
                <td width={50} valign='bottom' style={{paddingLeft: "5px", fontSize: "14px", fontWeight: "bold"}}>
                  {userName}
                </td>
                <td align='right' rowSpan={2} width={120}>
                  <Button variant="text" size='small' onClick={(e) => this.logout(e)} startIcon={<LogoutIcon />} style={{textTransform: "none"}}>Logout</Button>
                </td>                        
              </tr>
              <tr>
                <td valign='top' style={{paddingLeft: "5px", fontSize: "14px"}}>
                  {userEmail}
                </td>                        
              </tr>                      
            </tbody>
          </table>
        );
    }

    logout() {
        googleLogout();    
        this.props.setUserIsAuthenticatedAction(false);
        this.props.setUserIsDomainAllowed(false);
        this.props.setUserNameAction("");
        this.props.setUserEmailAction("");
        this.props.setUserPictureAction("");
        localStorage.clear();
      }

    render() {        
        const { isAuthenticated } = this.props;

        return (
            <header className="App-header">        
                <table border="0" width="100%">
                    <tbody>
                    <tr>
                        <td>
                          <a href="/">
                            <img src={logo} alt="logo" title='Home' />
                          </a>
                        </td>
                        <td>
                        <h1>Google Accelerator - Generative AI Sandbox</h1>
                        </td>                
                        {useGoogleAuth && isAuthenticated && <td align='right' style={{paddingRight: "20px"}}>
                        {this.userProfileWidget()}
                        </td>}
                    </tr>
                    </tbody>
                </table>
            </header> 
        )
    }
}
const mapStateToProps = state => ({
...state
});
const mapDispatchToProps = dispatch => ({
    setUserNameAction: (userName) => dispatch(usersAction.setUserNameAction(userName)),
    setUserEmailAction : (userEmail) => dispatch(usersAction.setUserEmailAction(userEmail)),
    setUserPictureAction: (userPicture) => dispatch(usersAction.setUserPictureAction(userPicture)),
    setUserIsAuthenticatedAction: (isAuthenticated) => dispatch(usersAction.setUserIsAuthenticatedAction(isAuthenticated)),
    setUserIsDomainAllowedAction: (isDomainAllowed) => dispatch(usersAction.setUserIsDomainAllowed(isDomainAllowed)),
  });
export default connect(mapStateToProps, mapDispatchToProps)(Header)
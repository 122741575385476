import React from 'react';
import TextField from '@mui/material/TextField';
export default class ResponseText extends React.Component {

    render() {
        const { response, rows } = this.props;

        return (
            <TextField                
              id="txtResponse"
              label="Response"
              style={{ width: 800 }}
              multiline={true}
              rows={rows}
              value={response}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
            />
          )
    }
}
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
export default class ModelsDropDown extends React.Component {

    render() {
        const { onChangeFunc, selectedModel  } = this.props;

        return (
          <TextField                
            id="selModel"
            select
            label="Model"                        
            style={{ width: 200 }}
            size='small'
            //helperText="Example: Given this job description:"
            align="left"
            //onChange={(e) => this.setState({selectedModel: e.target.value})}             
            onChange={onChangeFunc}
            //value={selectedOption.value || 0}
            value={selectedModel}
          >
              <MenuItem value='text-bison@001'>text-bison-001</MenuItem>
              <MenuItem value='text-bison@002'>text-bison-002</MenuItem>
              <MenuItem value='gemini-1.0-pro-001'>gemini-1.0-pro-001</MenuItem>
              <MenuItem value='gemini-1.5-pro-001'>gemini-1.5-pro-001</MenuItem>
              <MenuItem value='gemini-1.5-flash-001'>gemini-1.5-flash-001</MenuItem>
          </TextField>
          )
    }
}
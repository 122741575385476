import React from 'react';
//import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import twitter from '../img/twitter.svg';
import facebook from '../img/facebook.svg';
import linkedin from '../img/linkedin.svg';
export default class Footer extends React.Component {

    render() {
        //const { changePromptFunc, prompt, clearFunc, isLoading } = this.props;

        return (
            <footer className='App-footer'>
                <table border="0" width="100%" height="100%">
                    <tbody>
                    <tr>
                        <td align='left' style={{paddingLeft: "40px", width: "20%", textWrap: "nowrap"}}> 
                            Copyright © 2024 Opportunity@Work
                        </td>
                        <td align='center'>                        
                            <a href="/privacy-policy" className='App-link' rel="noreferrer" title="Privacy Policy">Privacy Policy</a>
                            <a href="mailto:product@stellarworx.org" style={{paddingLeft: "50px"}} className='App-link'>Contact</a>
                        </td>
                        <td align='right' style={{paddingRight: "40px", width: "20%"}}>
                            <a href='https://www.facebook.com/opportunityatwork' target="_blank" rel="noreferrer" style={{paddingRight: "20px"}} title='Facebook'>
                                <img src={facebook} alt="logo" width={'30px'} />
                            </a>
                            <a href='https://twitter.com/OpptyatWork' target="_blank" rel="noreferrer" style={{paddingRight: "20px"}} title='Twitter'>
                                <img src={twitter} alt="logo" width={'30px'} />
                            </a>
                            <a href='https://www.linkedin.com/company/opportunity-work' target="_blank" rel="noreferrer" title='LinkedIn'>
                                <img src={linkedin} alt="logo" width={'30px'} />
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>          
            </footer>     
        )
    }
}
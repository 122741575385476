import React from 'react';
export default class AccessRestricted extends React.Component {

    render() {
        return (
            <div align='center'>
                <h3>Access Restricted</h3>
            </div>
            );
    }
}
const setUserNameAction = (userName) => {    
    return {
      type: "setUserName",
      userName: userName
    }
  }

const setUserEmailAction = (userEmail) => {    
   return {
     type: "setUserEmail",
     userEmail: userEmail
   }
 }

 const setUserPictureAction = (userPicture) => {    
  return {
    type: "setUserPicture",
    userPicture: userPicture
  }
}

 const setUserIsAuthenticatedAction = (isAuthenticated) => {    
  return {
    type: "setUserIsAuthenticated",
    isAuthenticated: isAuthenticated
  }
}

const setUserIsDomainAllowed = (isDomainAllowed) => {    
  return {
    type: "setUserIsDomainAllowed",
    isDomainAllowed: isDomainAllowed
  }
}

 // eslint-disable-next-line
export default { setUserNameAction, setUserEmailAction, setUserPictureAction, setUserIsAuthenticatedAction, setUserIsDomainAllowed } ;
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import ListsHelper from '../helpers/listsHelper';
export default class TokenSlider extends React.Component {

    render() {
        const { onChangeFunc, tokens } = this.props;

        return (
            <>
                <Tooltip size='12' title="Output token limit determines the maximum amount of text output from one prompt. A token is approximately four characters.">
                  <label>Output token limit: </label>
                </Tooltip>
                <Slider
                  label="Output token limit"
                  aria-label="Output token limit"
                  size='small'                          
                  value={tokens}
                  valueLabelDisplay="auto"                                                    
                  marks={ListsHelper.marksTokens}
                  step={1}                          
                  min={1}
                  max={8192}              
                  width={200}
                  onChange={onChangeFunc}
                />
            </>
          )
    }
}
const rootReducer = (state, action) => {
    
    switch (action.type) {
      case "setUserName":        
        return {
          ...state,
          userName: action.userName
        };
      case "setUserEmail":
        return {
          ...state,
          userEmail: action.userEmail
        };
      case "setUserPicture":
        return {
          ...state,
          userPicture: action.userPicture
        };
      case "setUserIsAuthenticated":
        return {
          ...state,
          isAuthenticated: action.isAuthenticated
        };
      case "setUserIsDomainAllowed":
        return {
          ...state,
          isDomainAllowed: action.isDomainAllowed
        };
      default:
        return state;
    }
  };

export default rootReducer;
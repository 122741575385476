import React from 'react';
import { Container } from '@mui/material';

export default class PrivacyPolicy extends React.Component {

    render() {
        return (
            <Container id="page-content-wrapper" fluid style={{textAlign: 'left'}}>
                <center><h3>Privacy Policy- Opportunity@Work, Inc. Generative AI Sandbox</h3></center>
                <p>Last Updated: April 2024</p>
                <h4>Internal Use Only</h4>
                <p>This policy applies to the collection, use and disclosure of your information in connection with the Opportunity@Work, Inc. (“Opportunity@Work”, "O@W," "we," "us," or "our") Generative AI Sandbox located at 
                    <a href="https://genai-sandbox.stellarworx.org/">https://genai-sandbox.stellarworx.org/</a> (the "Sandbox").
                </p>
                <p>
                    Opportunity@Workrespects your privacy. We use your Personal Information to provide and improve our products and services. 
                    By using The Sandbox, you acknowledge our collection and use of information in accordance with this Policy and agree to be bound by the terms of this policy. 
                    <b>Please read this policy carefully before using the Sandbox.</b>
                </p>
                <ol>
                    <li>
                        <b>Purpose</b>
                        <p>The Sandbox is an internal development environment for Opportunity@Work employees to explore and test the capabilities of our generative AI technology.</p>
                    </li>
                    <li>
                        <b>Information Sharing</b>
                        <p>All information searched, generated, or rendered within the Sandbox is considered confidential and not for public dissemination. 
                            This includes text, code, data, and any other creative output produced by the AI. Do not share any information from the Sandbox with any external audience, 
                            including but not limited to: Clients or partner organizations; on Social media Platforms; in Press releases or other marketing materials; or with members of the general public.
                        </p>                        
                    </li>
                    <li>
                        <b>Information Collection</b>
                        <p>
                            We collect information automatically from you when you visit and use the Sandbox. Such information includes the following: 
                            We collect information about how you interact with the Sandbox to operate and improve the Sandbox, including to provide certain services and improve the user experience, 
                            address Sandbox integrity or security issues, and conduct research and analysis to maintain, protect, develop, and improve the Sandbox. 
                            This includes information such as the dates and times that you visit the Sandbox, the page you view, referring / exit URLs, and your browser type.                             
                        </p>
                        <p>In some cases, we collect this information through cookies and similar technologies.</p>
                        <p>To learn more about these technologies, please see the Cookies section below.</p>
                    </li>
                    <li>
                        <b>Device Information</b>
                        <p>To manage the Sandbox, we collect information about the devices you use to access the Sandbox, including your device model, IP address, inferred geolocation information, operating system, and unique device identifiers.</p>
                        <p>Transaction Information. We and, or our third party providers collect other transaction details related to your purchases through the Sandbox.</p>
                    </li>
                    <li>
                        <b>Cookies and Usage Reports</b>
                        <p>
                            By placing a small file known as a "cookie" on your computer (or other device), O@W’s and/or its third party service providers’ servers passively gather information about all visitors' 
                            use of the Sandbox for several reasons, including, but not limited to, the following: statistics collection and analysis, Sandbox optimization, analytics, and maintenance of user login information. 
                            The information that we and our third party service providers track with cookies includes, but is not limited to, the type of browser (such as Google Chrome or Internet Explorer) and Internet-connected devices 
                            being used to access the Sandbox, your Internet protocol (“IP”) address, your home domain or Internet service provider, your referrer URL (which is the URL for the website that you were viewing prior to visiting the Sandbox), 
                            how you were directed to the Sandbox, which specific pages you access on the Sandbox, how long you view each page, the time and date you access our Sandbox and the total number of visitors to the Sandbox and any portions thereof. 
                            We, and/or our third party service providers, may use the information collected from cookies or similar files on your computer for security purposes (such as authentication), to facilitate site navigation and to personalize your experience while visiting the Sandbox. 
                            This data helps us and our third party service providers improve our respective products and services.
                        </p>
                        <p>
                            Most popular Internet browser applications will allow you to configure the browser so as not to accept cookies. 
                            However, setting your browser to reject cookies may, in certain instances, prevent you from taking full advantage of the Sandbox (or some portion thereof) and its capabilities, or cause some features of the Sandbox to function improperly or more slowly.
                        </p>
                    </li>
                    <li>
                        <b>Google Analytics</b>
                        <p>
                            We reserve the right to use Google Analytics, a web analysis service provided by Google, in order to better understand your use of the Sandbox and how we can improve it. 
                            Google Analytics collects information such as how often users visit a website, what pages you visit when you do so, and what other websites you used prior to coming to such a website. 
                            Google Analytics collects only the IP address assigned to you on the date you visit a website, rather than your name or other directly identifying information. 
                            We do not combine the information collected through the use of Google Analytics with your Personal Information. 
                            Although Google Analytics plants a persistent cookie on your web browser to identify you as a unique user the next time you visit a website, the cookie cannot be used by anyone but Google. 
                            Google Analytics uses its cookie(s) and the information Google Analytics collects to track your online activities over time and across different websites when you use our Sandbox, and other websites. 
                            Google’s ability to use and share information collected by Google Analytics about your visits to the Sandbox is governed by the Google Analytics Terms of Service and the Google Privacy Policy. 
                            Google utilizes the data collected to track and examine the use of the Sandbox, to prepare reports on its activities, and share them with other Google services. 
                        </p>
                    </li>
                    <li>
                        <b>Online Account Management</b>
                        <p>
                            If you elect to subscribe to certain services available in the Sandbox, including the ability to access any password-protected areas of the Sandbox through your account, 
                            you will be required to submit Personal Information for the purpose of verifying your identity and your authority to manage the account. 
                            This Personal Information will be used exclusively by us and our third-party service providers for internal use and to respond to your service requests.
                        </p>
                    </li>
                    <li>
                        <b>Information Use</b>
                        <p>
                            We retain the Personal Information that we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with the services you requested or to comply with an applicable legal requirement).
                        </p>
                        <p>
                            Except as set forth in this policy, O@W will not use, disclose, or transfer your Personal Information unless:
                        </p>
                        <p>
                            <ul>
                                <li>you expressly authorize O@W to do so;</li>
                                <li>it is necessary to allow O@W’s service providers, agents, or other third parties to provide products or services for, or to O@W;</li>
                                <li>it is necessary in order to provide the Sandbox;</li>
                                <li>O@W is sending you other information that may be useful to you;</li>
                                <li>it is necessary to protect the confidentiality or security of your records;</li>
                                <li>it is necessary in connection with other business purposes including, without limitation, verifying your identity, investigating complaints about the Sandbox, risk assessment, security, fraud and crime prevention/detection, monitoring, research and analysis, marketing, customer purchasing preferences and trends and dispute resolution;</li>
                                <li>it is necessary to comply with law enforcement, a governmental mandate, or other legal requirement, if appropriate, for your protection or in connection with an investigation or prosecution of possible unlawful activity;</li>
                                <li>subject to applicable contractual or legal restrictions, it is necessary in connection with a sale of all or substantially all of the assets of O@W or the merger of O@W into another entity or any consolidation, share exchange, combination, reorganization, or like transaction in which O@W is not the survivor; or</li>
                                <li>it is otherwise necessary for O@W to disclose it as required or permitted by law.</li>
                            </ul>
                        </p>
                    </li>
                    <li>
                        <b>Information Sharing</b>
                        <p>
                            We may disclose Personal Information if we become subject to a subpoena or court order, or if we are otherwise legally required to disclose information. 
                            We may also use and disclose Personal Information to establish or exercise our legal rights, to enforce the Terms of Service, to assert and defend against legal claims, 
                            or if we believe such disclosure is necessary to investigate, prevent, or take other action regarding actual or suspected illegal or fraudulent activities or potential 
                            threats to the physical safety or well-being of any person.
                        </p>
                        <p>
                            It is possible that our organizational structure might change. In any such transactions, customer information generally is, and should be expected to be, one of the transferred business assets. 
                            Also, if any bankruptcy, liquidation or reorganization proceeding is brought by or against us, your information may be considered a company asset that may be sold or transferred to third parties.
                        </p>
                    </li>
                    <li>
                        <b>Information Choices</b>
                        <p>
                            When submitting information, corresponding, making requests for information, and otherwise interacting with O@W and its representatives through or in connection with the Sandbox, you generally choose what information to supply or submit. 
                            Please take care to share only such information as is needed or that you believe is appropriate.
                        </p>
                    </li>
                    <li>
                        <b>Information Protection</b>
                        <p>
                            O@W takes commercially reasonable measures to secure and protect information transmitted via or stored on the Sandbox. Nevertheless, no security system is impenetrable. 
                            We cannot guarantee that information that users of the Sandbox may happen to transmit or otherwise supply will be totally secure.
                        </p>
                        <p>
                            You agree to immediately notify the IT Department (<a href="mailto:IT@opportunity@Work.org">IT@opportunity@Work.org</a>) immediately of any breach of the Sandbox’ security, this Privacy Policy, or the Terms of Service of which you become aware.
                        </p>
                    </li>
                    <li>
                        <b>Data Protection Rights</b>
                        <p>
                            Subject to applicable data protection laws, you may have the right to access, update, and correct inaccuracies in your Personal Information. 
                            Typically, these rights should be exercised with our partners that help support our Sandbox rather than directly with us, for example, an employer with whom you were employed or applied for a job.
                        </p>                        
                    </li>
                    <li>
                        <b>Information Accuracy</b>
                        <p>
                            The Sandbox is under development, and the AI outputs may contain errors, biases, or misleading information. Do not rely on any information generated by the Sandbox for external purposes.
                        </p>
                    </li>
                    <li>
                        <b>Security and Privacy</b>
                        <p>
                            While we strive to maintain a secure environment, information within the Sandbox may not be fully secure. 
                            Use the Sandbox responsibly and avoid entering any sensitive or confidential data. 
                            Should you have any concerns about data security, please contact the IT department immediately at IT@opportunity@Work.org.
                        </p>
                    </li>
                    <li>
                        <b>Questions and Reporting Issues</b>
                        <p>
                            If you have any questions about the Sandbox or encounter any issues with the AI outputs, please contact the AI development team immediately at product@opportunityatwork.org.
                        </p>
                    </li>
                    <li>
                        <b>Compliance</b>
                        <p>
                            We may change this policy at any time and from time to time. The most recent version of the policy is reflected by the effective date located at the top of this policy. 
                            All such updates and amendments are effective immediately upon notice thereof, which we may give by any means, including, but not limited to, by posting a revised version of this policy or other notice in the Sandbox. .
                        </p>
                    </li>                    
                </ol>
                <br />
                <b>Contact:</b>
                <p>
                    For any questions regarding the Sandbox or this disclaimer, please contact the AI development team at <a href="mailto:product@opportunityatwork.org">product@opportunityatwork.org</a> or <a href="mailto:IT@opportunity@Work.org">IT@opportunity@Work.org</a>
                </p>
            </Container>
        )
    }
}
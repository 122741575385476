import axios from "axios";

const apiKey = process.env.REACT_APP_API_KEY
const apiHost = process.env.REACT_APP_API_HOST

const headers = {
  'X-Api-Key': apiKey,
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

export default class GenAIService {
  static vertexAI(prompt, model, temperature, tokens) {
      return axios.post(`${apiHost}/vertexAI?prompt=${prompt}&model=${model}&temperature=${temperature}&tokens=${tokens}`, {}, { headers })
      .then(res => {          
          return res.data;
      })
      .catch(error => {
          console.log('Error calling Vertex AI', error);
          throw new Error('Error calling Vertex AI', error);
      })
  }

  static ragAssets(prompt, model, temperature, tokens) {
    return axios.post(`${apiHost}/rag/assets?prompt=${prompt}&model=${model}&temperature=${temperature}&tokens=${tokens}`, {}, { headers })
    .then(res => {
        return res.data;
    })
    .catch(error => {
        console.log('Error calling RAG Assets', error);
        throw new Error('Error calling RAG Assets', error);
    })
  }

  static ragSMDM(prompt, model, temperature, tokens) {
    return axios.post(`${apiHost}/rag/smdm?prompt=${prompt}&model=${model}&temperature=${temperature}&tokens=${tokens}`, {}, { headers })
    .then(res => {
        return res.data;
    })
    .catch(error => {
        console.log('Error calling SMDM', error);
        throw new Error('Error calling SMDM', error);
    })
  }

  static ragPipeline(prompt, model, temperature, tokens) {
    return axios.post(`${apiHost}/rag/combined?prompt=${prompt}&model=${model}&temperature=${temperature}&tokens=${tokens}`, {}, { headers })
    .then(res => {
        return res.data;
    })
    .catch(error => {
        console.log('Error calling Combined RAG Pipeline', error);
        throw new Error('Error calling Combined RAG Pipeline', error);
    })
  }
    

  static listInsights(domain) {

    let apiUrl = `${apiHost}/insights`;
    
    if (domain !== "opportunityatwork.org" && domain !== "unicon.net") {
      apiUrl += `?domain=${domain}`;
    }

    return axios.get(apiUrl, { headers })
    .then(res => {
      const data = res.data;
      const insightsList = data.insights;

      let formattedData = [];        
      insightsList.forEach(insight => {
        formattedData.push({
          "id": insight.id.toString() || '',
          "name": insight.user_name || '',
          "email": insight.user_email || '',
          "prompt": insight.prompt || '',
          "response": insight.response || '',
          "response_status": insight.response_status || '',
          "response_time": insight.response_time || '',
          "helpful_answer": insight.helpful_answer || '',
          "user_feedback": insight.user_feedback || '',
          "query_datetime": insight.query_datetime || ''
        });
      });

      return formattedData;
    })
    .catch(error => {
      console.log('Error getting prompt insights', error);
      return error;
    });
  }

  static updateInsight(insightId, helpfulAnswer, feedbackValue = null) {

      const apiUrl = `${apiHost}/insights/update`
      let params = `?id=${insightId}&helpfulAnswer=${helpfulAnswer}`;
      if (feedbackValue)
        params += `&feedbackValue=${feedbackValue}`;

      return axios.patch(`${apiUrl}${params}`, {}, { headers })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log('Error calling Insights Update', error);
        return error;
      });
  }

  static saveInsight(user_name, user_email, inputPrompt, response, responseTime) {
      var jsonReq = {
        "user": user_name,
        "email": user_email,
        "prompt": inputPrompt,
        "response": response,
        "responseTime": responseTime != null ? parseInt(responseTime) : null
      }

      return axios.post(`${apiHost}/insights/save`, jsonReq , { headers })
      .then(res => {
          return res.data;
      })
      .catch(error => {
          console.log('Error calling Insights Save', error);
          return error;     
      });
  }

  static getIndustry(jobTitle) {
    return axios.get(`${apiHost}/jobs/industry?job_title=${jobTitle}`, { headers })
    .then(res => {        
        return res.data;
    })
    .catch(error => {
        console.log('Error calling getIndustry', error);
        return error;     
    });
  }
}
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ThumbUpOutlined, ThumbDownOutlined } from '@mui/icons-material';
import GenAIService from '../services/genAIService';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 1,
  p: 4,
};

const defaultValue = 'Tell us a bit more about your experience';

export default class FeedbackThumbs extends React.Component {

  state = {
    showModal: false,
    helpfulAnswer: true,
    feedbackValue: defaultValue
  }

  

  thumbsClick(e, helpfulAnswer) {
    const { insightId } = this.props;
    if (insightId) {
      this.setState({ helpfulAnswer: helpfulAnswer === 'true'});
      this.openModal(e);      
      GenAIService.updateInsight(insightId, helpfulAnswer).then(data => {          
        const rowsAffected = data.rowsAffected;
        if (rowsAffected === 1) {
          console.log("Feedback updated");
        }
      })
      .catch(error => {
        console.log('Error calling Insights Update', error);
      });
    }
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ showModal: false });
  }

  onFocus(e) {
    e.preventDefault();
    if (this.state.feedbackValue === defaultValue)
      this.setState({ feedbackValue: '' });
  }
  
  changeValue(e) {
    e.preventDefault();
    this.setState({ feedbackValue: e.target.value });
  }

  submitFeedback(e) {
    e.preventDefault();
    const { insightId, clearInsightFunc } = this.props;
    const { helpfulAnswer, feedbackValue } = this.state;
    console.log(helpfulAnswer, feedbackValue);

    if (insightId) {
      GenAIService.updateInsight(insightId, helpfulAnswer.toString(), feedbackValue).then(data => {          
        const rowsAffected = data.rowsAffected;
        if (rowsAffected === 1) {
          alert("Thanks for your feedback!");
          this.closeModal(e);
          clearInsightFunc(e);
        }
      })
      .catch(error => {
        console.log('Error calling Insights Update', error);
      });
    }
  }

  modal() {
    const { showModal, helpfulAnswer, feedbackValue } = this.state;

    return (
      <Modal
        open={showModal}
        onClose={(e) => this.closeModal(e)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Submit feedback</h3>
          <p>{helpfulAnswer ? 'What went well?' : 'What went wrong?'}</p>
          <TextField id="outlined-basic" hiddenLabel variant="outlined" value={feedbackValue} style={{ width: 630 }} 
            onChange={(e) => this.changeValue(e)} 
            onFocus={(e) => this.onFocus(e)}
            multiline
            rows={3}            
          />          
          <div align="right" style={{ marginTop: 20 }}>
            <Button variant="text" id="btnCancelFeedback" size='small' onClick={(e) => this.closeModal(e)}>Cancel</Button>
            <Button variant="contained" id="btnSubmitFeedback" size='small' style={{marginLeft: 10}} onClick={(e) => this.submitFeedback(e)}>Submit</Button>
          </div>
        </Box>
      </Modal>
    )
  }

  render() {
    return (
      <div>
          <Tooltip size="12" title="Submit a positive feedback for the response of the last prompt">
              <IconButton color='primary' onClick={(e) => this.thumbsClick(e, 'true')}>
              <ThumbUpOutlined />
              </IconButton>
          </Tooltip>
          <Tooltip size="12" title="Submit a negative feedback for the response of the last prompt">
              <IconButton color='primary'  onClick={(e) => this.thumbsClick(e, 'false')}>
              <ThumbDownOutlined />
              </IconButton>
          </Tooltip>
          {this.modal()}
      </div>
    )
  }
}
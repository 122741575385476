import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import ListsHelper from '../helpers/listsHelper';
export default class TemperatureSlider extends React.Component {

    render() {
        const { onChangeFunc, temperature } = this.props;

        return (
            <>
                <Tooltip size='12' title="Temperature controls the degree of randomness in token selection. Lower temperatures are good for prompts that expect a true or correct response, while higher temperatures can lead to more diverse or unexpected results. With a temperature of 0 the highest probability token is always selected.">
                    <label>Temperature: </label>
                </Tooltip>
                <Slider
                    label="Temperature"
                    aria-label="Temperature"
                    size='small'                          
                    value={temperature}
                    valueLabelDisplay="auto"                                                    
                    marks={ListsHelper.marksTemperature}
                    step={0.1}                          
                    min={0}
                    max={1}              
                    width={200}
                    //onChange={(e) => this.setState({ temperature: e.target.value })}
                    onChange={onChangeFunc}
                />
            </>
          )
    }
}
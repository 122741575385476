import React from 'react';
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import { GenerativeModel } from '@google/generative-ai';

import GenAIService from '../services/genAIService';
import LoadingDialog from './LoadingDialog';
import ResponseText from './ResponseText';
import PromptInsightsButton from './PromptInsightsButton';
import ParametersForm from './ParametersForm';
import FeedbackThumbs from './FeedbackThumbs';
import FreeForm from './FreeForm';
//import SamplesForm from './SamplesForm';
import { Navigate } from 'react-router-dom';

const useGoogleAuth = process.env.REACT_APP_USE_GOOGLE_AUTH

class PromptForm extends React.Component {
    state = {
        isLoading: false,        
        response: '',
        prompt: '',
        temperature: 0.2,
        tokens: 1024,
        selectedModel: 'gemini-1.5-flash-001',
        insightId: 0,
        selectedApi: 'combined',
    }

    clearResponse(e) {
      e.preventDefault();      
      this.setState( {
        response: '',
        insightId: 0
      });
    }

    submitForm(e) {
      e.preventDefault();

      const { prompt, selectedModel, temperature, tokens, selectedApi } = this.state;
      
      const startDate = new Date();
      this.setState({ isLoading: true, insightId: 0});
      
      if (selectedApi  === 'vertexai') {     
        GenAIService.vertexAI(prompt, selectedModel, temperature, tokens).then(data => {
          const answer = data.answer || '';
          this.handleApiResponse(answer, startDate);
        })
        .catch(error => {
          this.handleApiResponse('Error: ' + error.message, startDate);
        })
      }
      else if (selectedApi === 'oaw_assets') {
        GenAIService.ragAssets(prompt, selectedModel, temperature, tokens).then(data => {          
          const answer = data.answer || '';
          this.handleApiResponse(answer, startDate);
        })
        .catch(error => {
          this.handleApiResponse('Error: ' + error.message, startDate);
        })
      }
      else if (selectedApi === 'smdm') {
        GenAIService.ragSMDM(prompt, selectedModel, temperature, tokens).then(data => {          
          const answer = data.answer || '';
          this.handleApiResponse(answer, startDate);
        })
        .catch(error => {
          this.handleApiResponse('Error: ' + error.message, startDate);
        })
      }
      else if (selectedApi === 'combined') {
        GenAIService.ragPipeline(prompt, selectedModel, temperature, tokens).then(data => {          
          const answer = data.answer || '';
          this.handleApiResponse(answer, startDate);
        })
        .catch(error => {
          this.handleApiResponse('Error: ' + error.message, startDate);
        })
      }
    }

    calculateResponseTime(startDate) {
      var endDate   = new Date();
      var responseTime = (endDate - startDate) / 1000;
      return responseTime;
    }

    handleApiResponse(response, startDate) {      
      var responseTime = this.calculateResponseTime(startDate)
        this.setState({ response: response, isLoading: false});
      try {
        this.saveInsight(response, responseTime);
      }
      catch {}
    }

    saveInsight(response, responseTime) {
      const { prompt } = this.state;      
      const { userName, userEmail } = this.props;
      
      GenAIService.saveInsight(userName, userEmail, prompt, response, parseInt(responseTime))
      .then(data => {
        const insightId = data.id;
        this.setState({ insightId: insightId });
      })
      .catch(error => {
        
      });
    }

    parameters() {
      const { temperature, tokens, selectedModel } = this.state;

      return (
        <ParametersForm temperature={temperature} tokens={tokens} selectedModel={selectedModel} 
          modelChangeFunc={(e) => this.setState({selectedModel: e.target.value})} 
          temperatureChangeFunc={(e) => this.setState({temperature: e.target.value})}
          tokenChangeFunc={(e) => this.setState({tokens: e.target.value})} 
          changeApiFunc={(e) => this.setState({selectedApi: e.target.value})}
          selectedApi={this.state.selectedApi}
          />
      )
    }

    changeState(e, name, newValue = null) {
      e.preventDefault();
      if (newValue !== null)
        this.setState({ [name]: newValue });
      else
        this.setState({ [name]: e.target.value });
    }

    oawInternal() {
      return (
        <>
          <gen-search-widget
              id="searchWidget"
              configId="53d737aa-1c36-40ef-a2ca-10b948329a03"
              location="us"
              triggerId="btnLoadSearchWidget"              
          />
          <br /><br />
          <Button variant="outlined" sx={{ display: "block" }} id="btnLoadSearchWidget" name="btnLoadSearchWidget" style={{textTransform: "none"}}>OAW Assets Widget</Button>
        </>
      )
    }

    render() {
      const { isLoading, prompt, response, insightId } = this.state;
      const { isAuthenticated, isDomainAllowed } = this.props;

      if (useGoogleAuth && !isAuthenticated) { 
        return <Navigate to="/login" />
      }
      
      if ((useGoogleAuth && isAuthenticated && isDomainAllowed) || !useGoogleAuth ) {
        return (
          <>
            <table border="0" width="98%" style={{marginTop: "10px", padding: "5px"}}>
              <tbody>
                <tr>
                  <td width={"50%"} align='center'>
                    
                  </td>
                  <td>
                    <div style={{ textAlign: "left" }}>
                      <PromptInsightsButton />
                    </div>                  
                  </td>
                </tr>
                <tr>
                  <td width={"50%"} align='center' valign='top'>
                    <Box
                      component="form"
                      id="prompForm"
                      onSubmit={(e) => this.submitForm(e)}
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FreeForm changePromptFunc={(e) => this.setState({ prompt: e.target.value })} submitFormFunc={(e) => this.submitForm(e)} prompt={prompt} clearFunc={(e) => this.setState({ prompt: ''})} isLoading={isLoading} />                      
                      <br />
                      {this.oawInternal()}
                    </Box>
                  </td> 
                  <td valign='top' align='left'>
                    {isLoading && <LoadingDialog message="Loading..." />}
                    {!isLoading && <ResponseText response={response} rows={15} />}
                    { !isLoading && insightId > 0 && <FeedbackThumbs clearInsightFunc={(e) => this.changeState(e, 'insightId', 0)} insightId={insightId} /> }
                    <br /><br />
                    {!isLoading && this.parameters()}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      }
    }
}

PromptForm.propTypes = {
  //onChangeFunc: PropTypes.func,
  //selectedReleases: PropTypes.array
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  //setReleasesAction: (releasesList) => dispatch(setReleasesAction(releasesList)),
  //setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptForm);